.geojson-toggle{
    /* top: 90px; */
    /* left: 47px; */
    /* position: absolute;
    z-index: 1000; */
    /* background: white; */
    padding: 4px;
    /* border-radius: 3px; */
    display: grid;
    /* border: 2px solid #c2bebe; */
  }
 